<template>
    <v-overlay  absolute color="primary lighten-5">
          <v-card class="mx-auto dark--text px-6 pt-6 pb-2" color="white">
            <div class="d-grid justify-center align-center">
                <v-img class="px-9" width="200" height="150" src="@/assets/icons/security-payment.png"/>
            </div>
            <div class="secondary--text text-center font-weight-bold mb-2">
                Take a moment!. Your Access role was changed.
            </div>
            <div class="primary--text text-center font-weight-medium mb-2">
                Page is going to reload on after donation So, Do want to continue this page?
            </div>
            <v-card-actions class="d-flex justify-end align-center mt-2">
                <v-btn small color="secondary" @click="returnInfo">Return to Landing Page</v-btn>
                <v-btn small color="primary" @click="updatedLS">Continue..!</v-btn>
            </v-card-actions>
          </v-card>
    </v-overlay>
</template>

<script>
export default {
    methods: {
        updatedLS() {
            this.$store.commit('setLockScreenEnable', false);
            localStorage.setItem('pageLock', true);
        },
        returnInfo() {
            this.$store.commit('setLockScreenEnable', false);
            localStorage.removeItem('pageLock');
            this.$router.push('info');
        }
    }
}
</script>
